<template>
  <KeepAlive>
    <b-card-code>
      <validation-observer ref="simpleRules">
        <b-form v-if="!editAdmin" style="" @submit.prevent>
          <b-row>
            <!--  name -->
            <b-col cols="12">
              <b-form-group label="اسم المشرف" label-for="v-first-name">
                <validation-provider
                  #default="{ errors }"
                  name="اسم المشرف"
                  rules="required"
                >
                  <b-form-input
                    id="v-first-name"
                    v-model="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="اسم المشرف "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--  username -->
            <b-col cols="12">
              <b-form-group label="اسم المستخدم" label-for="v-username">
                <validation-provider
                  #default="{ errors }"
                  name="اسم المستخدم"
                  rules="required"
                >
                  <b-form-input
                    id="v-first-name"
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    placeholder="اسم المشرف "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--  password -->
            <b-col cols="12">
              <b-form-group label=" كلمة المرور" label-for="v-password">
                <validation-provider
                  #default="{ errors }"
                  rules="required|password"
                  name="password"
                  vid="password"
                >
                  <b-form-input
                    v-model="password"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="كلمة السر"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="validationForm"
              >
                إضافه
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="primary"
                @click="this.reset"
              >
                إعادة تهيئة
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="back"
                variant="primary"
                style="margin-right: 15px"
                @click="this.back"
              >
                رجوع
              </b-button>
            </b-col>
          </b-row>
        </b-form>

        <b-form v-else style="" @submit.prevent>
          <b-row>
            <!--  name -->
            <b-col cols="12">
              <b-form-group label="اسم المشرف" label-for="v-first-name">
                <validation-provider
                  #default="{ errors }"
                  name="اسم المشرف"
                  rules="required"
                >
                  <b-form-input
                    id="v-first-name"
                    v-model="admin.userDisplayName"
                    :state="errors.length > 0 ? false : null"
                    placeholder="اسم المشرف"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--  username -->
            <b-col cols="12">
              <b-form-group label="اسم المستخدم" label-for="v-name">
                <validation-provider
                  #default="{ errors }"
                  name="اسم المستخدم"
                  rules="required"
                >
                  <b-form-input
                    id="v-username"
                    v-model="admin.userName"
                    :state="errors.length > 0 ? false : null"
                    placeholder="اسم المشرف"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
           
            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="validationForm"
              >
                تعديل
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="primary"
                @click="this.reset"
              >
                إعادة تهيئة
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="back"
                variant="primary"
                style="margin-right: 15px"
                @click="this.back"
              >
                رجوع
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
  </KeepAlive>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BFormCheckboxGroup,
  BFormRadio,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import store from "@/store/index";
import router from "@/router";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckboxGroup,
    BFormRadio,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  },

  directives: {
    Ripple,
  },
  props: ["id"],
  data() {
    return {
      // ? Default locale for VeeValidate is 'en'
      locale: "ar",
      // for validation
      required,
      name: null,
      username: null,
      password: null,
      editAdmin: null,
      admin: null,
      admins:[]
    };
  },
  created(){

  },
  mounted() {
    // switch to arabic in validation
    localize(this.locale);
    if (this.id) {
      this.editAdmin = true;
      this.admin = this.admins.find(
        (i) => i.id == this.$route.params.id
      );
    }
  },
  methods: {
    async GetAdmins(){
      try {
       const response =  await this.$store.dispatch('GetAdmins');
       if(response.status = 200){
          this.admins = response.data
     }
      } 
      catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:  'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
              icon: 'TrashIcon',
              variant: 'danger',
            },
          })
      }
    },
    validationForm() {
      if (!this.editAdmin) {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            // eslint-disable-next-line
            this.AddAdmin();
          }
        });
      } else {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            // eslint-disable-next-line
            this.EditAdmin();
          }
        });
      }
    },

    async AddAdmin() {
      const admin = {
        UserDisplayName: this.name,
        userName: this.username,
        password: this.password,
        Role: "Admin",
      };
      try {
        const response = await this.$store.dispatch("AddAdmin", admin);

        if ((response.status = 200)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "تم الإضافة بنجاح",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$router.push({
            name: "admins",
          });
        }
      } catch (err) {
        if (err.response) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data,
              icon: "XIcon",
              variant: "warning",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "فشل الاتصال بالسيرفر يرجى المحاولة لاحقا",
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
      }
    },
    async EditAdmin() {
      if (this.editAdmin) {
        const payload = {
          id:this.admin.id,
          data:{
               userDisplayName: this.admin.userDisplayName,
               userName: this.admin.userName,
          }
        }
       
 try {
        const response = await this.$store.dispatch("UpdateAdmin", payload);

        if ((response.status = 200)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "تم التعديل بنجاح",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$router.push({
            name: "admins",
          });
        }
      } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "فشل الاتصال بالسيرفر يرجى المحاولة لاحقا",
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        
      }
      }
    },
    reset() {
      if (this.editAdmin) {
        this.admin.userDisplayName = null;
        this.admin.userName = null;
        this.admin.password = null;
      } else {
        this.name = null;
        this.username = null;
        this.password = null;
      }
    },

    back() {
      router.push("/admins");
    },
  },
};
</script>
